@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import "./bootrap.css";
/* @import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css"; */

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;


}

/* HEADER CSS */
header {
    position: absolute;
    /* top: 83px; */
    width: 100%;
    z-index: 1;
}

.main-navs li {
    margin-right: 13px;
    /* padding-top: 12px; */
}

.main-navs li:last-child {
    margin-right: 0;
}

.main-navs a {
    font-size: 16px;
    color: white;
    text-transform: capitalize;
    font-weight: bold;
    padding-bottom: 0;

}

.main-navs a:hover {
    color: #f90946;
}


.recent-project {
    padding-bottom: 100px;
}

.nav-tabs.tabs-inner-portfolio .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background: none;
    border: none;
    color: white;
}

.nav-tabs.tabs-inner-portfolio .nav-item .nav-link {
    position: relative;
    padding: 0;
}

.nav-tabs.tabs-inner-portfolio .nav-item .nav-link {
    color: #666;
}

.nav-tabs.tabs-inner-portfolio .nav-item .nav-link.active {
    color: white;
}

.nav-tabs.tabs-inner-portfolio .nav-item {
    font-size: 18px;
    font-weight: bold;
    padding: 20px;


}

.portfolio-tabs .nav-pills .nav-link {
    border: 1px solid white;
    margin-right: 20px;
    margin-bottom: 40px;
}

.nav-pills .nav-link.active {
    color: #d3073e;
    font-weight: bold;
}

.nav-tabs.tabs-inner-portfolio {
    border-bottom: 1px solid #d3073e;


}

/* .nav-tabs.tabs-inner-portfolio .nav-item .nav-link.active {
    content: "";
    width: 100%;
    height: 4px;
    background-color: white;
    position: absolute;
} */

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border: 0;
}

.innner-banner-service {
    background-image: url(../images/contentbg.png);
    background-repeat: no-repeat;
    background-size: cover;
}


.privacy-content h4 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;
}

.privacy-content h4 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;
}

.privacy-content {
    margin: 40px 0;
    font-family: 'Poppins', sans-serif;
}

.terms-heading {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.privacy-content h4 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;

}

.privacy-content h4 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;

}

.privacy-content {
    margin: 40px 0;
    font-family: 'Poppins', sans-serif;

}

.terms-heading {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;

}

/* banner css */

.banner {
    position: relative;
    overflow: hidden;
}


.home-page-bg {
    position: absolute;
    top: 0;
    z-index: -1;
}

.home-page-bg img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.inner-banner-content {
    padding-top: 200px;
    padding-bottom: 200px;
    z-index: 9999;
}

/* .banner-section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
} */

.banner-para {
    color: white;
    line-height: 1.8
}

.banner-left-content {
    /* padding-top: 00px; */
}

/* .banner-content {
    padding: 385px 0 340px 0;
} */

.banner-sub-heading {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: white;

}

.banner-heading {
    font-size: 86px;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color: white;

}

.banner-content p {
    color: white;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 30px;
}

/* 
.banner-btns {
    color: white;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    background-color: #f90946;
    padding: 10px 30px;
    border-radius: 60%; 


}
 */
.banner-img {
    position: absolute;
    top: 15%;
    right: 0;
}



/* banner-buttons */
.form-send-button {
    /* padding: 1.3em 3em; */
    /* font-size: 12px; */
    letter-spacing: 2.5px;
    font-weight: 500;
    color: white;
    background-color: #e70842;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.form-send-button:hover {
    background-color: white;
    box-shadow: 0px 15px 20px rgba(29, 2, 0, 0.4);
    color: #000000;
    transform: translateY(-7px);
}

.form-send-button:active {
    transform: translateY(-1px);
}



/* banner-buttons */
.banner-btns {
    background: #eb0843;
    border: none;
    padding: 10px 30px;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    /* width: 120px; */
    text-transform: uppercase;
    cursor: pointer;
    /* transform: skew(-28deg); */
    color: white;
    text-decoration: none;
    /* border-radius: 89% 29% 90% 23% / 0% 100% 0% 100%; */
    border-radius: 5px;
}


.banner-btns span {
    /* display: inline-block; */
    /* transform: skew(21deg); */

}

/* .banner-btns::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    left: 0;
    background: rgb(20, 20, 20);
    opacity: 0;
    z-index: -1;
    transition: all 0.5s;
} */

.banner-btns:hover {
    color: #fff;



}

.banner-btns:hover::before {
    left: 0;
    right: 0;
    opacity: 1;

}

/* banner-btns-end */


/* design-agency css */
.design-agency-title {
    font-size: 36px;
    color: #000000;
    font-family: 'Raleway', sans-serif;
    /* font-style: italic; */
    font-weight: 600;

}

.nav-link:focus,
.nav-link:hover {
    color: #d3073e;
}

.mobile-social-links li {
    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    margin-right: 10px;
    margin-top: 20px;
}

.mobile-social-links li a {
    color: red;
}

.design-agency-title span {
    color: #d3073e;
    font-size: 62px;
    font-weight: bold;
    font-style: normal;

}

.design-agency-sec {
    margin-top: 50px;
    margin-bottom: 95px;
}

.design-agency-content .para {
    font-size: 17px;
    color: #101010;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    margin-bottom: 70px;


}


.design-agency-box-heading {
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    color: #d3073e;
    font-weight: 600;
    margin-top: 36px;
    margin-bottom: 20px;
}

.design-agency-box p {
    color: #878787;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 0;
}

.contact-us-detail p {
    color: #000;
    font-size: 17px;
}

/* call-to-action css */
.call-to-action-sec {
    background-color: #d3073e;
}

.call-to-action-content {
    padding: 75px 0 20px 0;
}

.call-to-action-heading {
    font-size: 44px;
    color: white;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;

}

.call-to-action-content p {
    font-size: 17px;
    font-weight: 500;
    color: white;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 33px;
    margin-top: 15px;
}

.call-to-action-btn {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: bold;
    background-color: transparent;
    border: 5px solid white;
    padding: 10px 20px;
    text-transform: uppercase;
    margin-right: 38px;
    text-decoration: none;

}

.call-to-action-btn:hover {
    color: white;
}

.call-to-action-order-btn {
    font-size: 17px;

    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    padding: 15px 50px;
    border: none;
    text-transform: uppercase;
    border-radius: 5px;
}

.call-to-action-order-btn a {
    text-decoration: none;
    color: #d3073e;

}

.call-to-action-order-btn:hover {
    color: white;
}

.call-to-action-img {
    margin-top: -55px;
}

/* call-to-action end */

/* recent-projects-css */
.recent-project {
    background-color: #211e1e;
}

.recent-project-content {

    padding: 80px 0 80px 0;
}

.recent-project-title {
    color: #d3073e;
    font-size: 62px;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;

}

.recent-project-title span {
    color: white;
}

.title-para {
    color: white;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;

}

.recent-project-border-bottom {
    background-color: #7f7f7f;
    height: 2px;
    width: 55%;
    margin: 0 auto;
}

.title-para {
    color: #fbfbfb;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;

}

.nav-pills .nav-link.active {
    border: 3px solid #d3073e;
    background: transparent;
    height: 45px;
    width: 175px;
    padding: 0;
    /* background: transparent!important; */
}

.nav-pills .nav-link {

    height: 45px;
    width: 175px;
    padding: 0;
    color: white;
    border-color: #d3073e;


}

.recent-project-tabs li {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
}

/* tabs-img */
.tabs-img-1 {
    margin-top: -155px;
    margin-bottom: 20px;
}

/* tabs-img-end */
/* recent-projects-end */


/* custom-package css */
.custom-package {
    background-image: url(../logo-finitive-slicing/create-custom-package/Group-15.png);
    padding: 54px 0 80px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}



.custom-package-heading {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    font-weight: 600;

}

.custom-package-heading span {
    color: white;
    font-size: 62px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;


}

.custom-package p {
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;

}

.custom-package-icon-1-heading {
    font-size: 17px;
    font-weight: 600;
    color: white;
    margin-top: 16px;
}

/* about us css */
.about-us {
    background-image: url(../logo-finitive-slicing/about-us/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.about-us-content {
    padding: 150px 0;
}

.about-us-title {
    font-size: 62px;
    font-weight: bold;
    color: white;
    margin-bottom: 30px;
}

.about-us-content p {
    color: white;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 40px;
}

.about-us-content-2 {
    padding-top: 280px;
}

.about-us-icon-heading {
    font-size: 50px;
    font-weight: 600;
    color: white;
}

.about-us-icon-1 img {
    margin-right: 10px;
}

.margin-right {
    margin-right: 50px;
}

.about-us-icon-heading>div {
    font-size: 17px;
    font-weight: 600;
    color: white;
    margin-top: 5px;
}

.about-us-bottom-heading {
    margin-top: 40px;
    font-size: 17px;
    font-weight: 600;
    color: white;
}

.social-images {
    margin-top: 28px;
    margin-right: 25px;
}

.logo-design-box-content ul {
    height: 260px;
    overflow-x: hidden;
}

.logo-design-box-content ul::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
}

.logo-design-box-content ul::-webkit-scrollbar-thumb {

    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #d62929
}

.logo-design-box-content ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.logo-design-box-content ul li {
    position: relative;
}

.logo-design-box-content ul li:before {
    content: "\f111";
    position: absolute;
    width: 8px;
    height: 8px;
    font: normal normal normal 14px/1 FontAwesome;
    left: -16px;
    font-size: 7px;
    top: 7px;
    color: #d7073e;

}

.logo-design-box-2:hover h1,
.logo-design-box:hover h1 {
    background: #d7073e;
    border-radius: 0;
    transition-duration: .5s;
}

.logo-design-box-2:hover,
.logo-design-box:hover {
    transform: scale(1.1);
    transition-duration: .5s;
}

/* work-process css */
.work-process {
    background-image: url(../logo-finitive-slicing/work-process/bg-element.png);
    background-repeat: no-repeat;
}

.work-process-title {
    font-size: 55px;
    color: #d3073e;
    font-weight: bold;
    margin: 50px 0;
}

.work-process-title span {
    font-size: 55px;
    color: #211e1e;
    font-weight: bold;

}

.work-process-heading {
    color: #3f3f3f;
    font-size: 17px;
    /* font-size: 600; */
}

.work-process-icon img {
    text-align: center;
}

.work-process-content {
    padding: 53px 0 112px 0;
}

/* feedback-sec css */
.feedback-sec {
    background-image: url(../logo-finitive-slicing/testimonial/bg.png);
    background-repeat: no-repeat;
    background-size: cover;

}

.feedback-sec-content {
    padding: 200px 0;
}

.feedback-sec-heading {
    color: white;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;

}

.feedback-sec-content-1 p,
.feedback-sec-content-box-3 p,
.feedback-sec-content p {
    color: white;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.feedback-sec-content-1 .number {
    color: white;
    font-size: 48px;
    font-weight: bold;
}

.feedback-sec-content .number span {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.feedback-icon i {
    background-color: #211e1e;
    padding: 20px;
    color: white;
    margin-right: 15px;
    margin-top: 25px;
}

.feedback-icon .fa-arrow-left {
    color: red;
    background-color: white;
}

.feedback-sec-box-heading {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-top: 17px;
}

.feedback-sec-box-sub-heading {
    color: #8c949d;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 15px;
}

.feedback-sec-content-1 {
    padding-top: 264px;
}

.feedback-sec-box {
    background-color: #211e1e;
    padding: 38px 22px 50px 16px;
    border-radius: 10px;
    margin-top: -26px;
    margin-right: 20px;
    /* margin-left: -20px; */

}


/* .feedback-sec-content-box-3 {
    position: absolute;
    top: 21%;
    right: 1%;
} */

.feedback-sec-content-box-1 {
    margin-top: -10px;
}

/* feedback css end */










/* forms */
.forms-sub-title {
    color: white;
    font-size: 34px;
    /* font-style: italic; */
    font-family: 'Lora', serif;
    margin-top: 100px;

}

.form-content {
    padding-bottom: 100px;
}

.forms-title {
    color: white;
    font-size: 42px;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 100px;



}

.form-send-button {
    width: 230px;
    height: 50px;
    background-color: #e70842;
    font-size: 18px;
    font-weight: 500;
    color: white;
    border-radius: 60px;
    margin-top: 35px;


}

.forms {
    background-color: #171515;
    padding-bottom: 50px;
}

/* about us page design */


/* end */

/* forms-css from ui */

.email-phone {

    font-size: 16px;
    color: #ffffff;

}

.email,
.phone {
    margin-top: 50px;
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 25px;

}

.email img,
.phone img {
    margin-right: 10px;
}

.form-floating .form-control {
    background-color: #282727;
    color: #636363;
    border: none !important;
    border-radius: 10px;
    padding-top: 23px;
    min-height: calc(192px + 0.75rem + 2px);
}

.form-floating .form-control:focus {
    box-shadow: none;
    color: white;
}

.email-phone img {
    margin-right: 10px;
}

.form-control:focus {
    box-shadow: none;
}

/* form */

.mb-60 {
    margin-bottom: 60px;
}

.modal-field-3 .form-control,
.modal-field-4 .form-control,
.modal-field-2 .form-control,

.modal-field-1 .form-control {
    padding: 0.375rem 1.75rem;
}


.forms .form-control {
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;
    border-bottom: 3px solid #75798d;
    color: white;
    border-radius: 0;
    padding-top: 4px;


}

.forms-comment-box #floatingTextarea {
    background-color: #282727;
    border-radius: 20px;
    padding-top: 22px;
    color: #636363;
}

.forms .form-select {
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;
    border-bottom: 3px solid #75798d;
    color: white;
    border-radius: 0;
    padding-top: 4px;
}

.contact-us-form {
    color: white;
}

.forms ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: white !important;
}

option {
    color: black;
}

.forms .form-select {
    height: 40px
}

.forms .form-select:hover {
    background-color: transparent;
}

.forms input {
    color: black;
}

.forms .form-btn {
    margin-top: 10px;
    padding: 12px 30px;
    background-color: #ffffff;
    border-radius: 30px;
    color: #030f3d;
    text-decoration: none;
    font-weight: 500;
}

.forms .form-check-label {
    color: #ffffff;
}

/* forms-ens */

.forms .contact-us-form #exampleInputEmail1:focus {
    color: white;
    box-shadow: none;
    background: transparent;
    border-bottom: 3px solid white;
    border-radius: 0px;
}

.forms .contact-us-form .form-select:focus {
    border-bottom: 3px solid #545252;
    border-radius: 0px;
    background-color: transparent;
    color: white;
    box-shadow: none;
}

.forms .contact-us-form #exampleInputEmail1 {
    border-radius: 0px;
    font-size: 21px;
    border-bottom: 3px solid #545252;
}

.forms .contact-us-form .form-select:focus {
    border-bottom: 3px solid #545252;
    border-radius: 0px;
    background-color: transparent;
    color: white;
    box-shadow: none;
}

.forms .contact-us-form .form-select {
    /* width: 30%; */
    font-size: 21px;
}

.section-forms-textare .form-control {
    min-height: 300px;
}


.section-forms-fields .consultaion-form {
    margin-top: 40px;
}

.forms .form-select:hover {
    background-color: transparent;
}

.forms .form-select {
    height: 40px
}

.forms .form-select,
.forms .form-control {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 3px solid #545252;
    color: white;


}

/* footer */
footer {
    padding-top: 80px;
    background-color: #e70842;
}

.footer-content p {
    color: white;
    font-size: 13px;
    font-weight: 400;
    margin-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 40px;

}

.footer-searchbar .form-control {
    height: 40px;
    color: #747474;
    font-size: 12px;
    font-weight: 400;
    border-radius: 6px;

}

.footer-searchbar input {
    color: white;
}

.footer-searchbar .input-group-text {
    background-color: black;
    border-color: transparent;
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.footer-icons {
    padding-top: 20px;
}

.footer-title {
    color: white;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 18px;
    text-transform: uppercase;

}

.footer-logo-heading {
    font-size: 22px;
    color: white;
    font-weight: bold;
}

.bottom-line {
    background-color: white;
    height: 2px;
    width: 60px;
}

footer ul {
    padding-left: 0;
    padding-top: 30px;
}

footer li {
    list-style: none;

}

footer li {
    padding-bottom: 10px;
}

.footer-icons {
    padding-right: 2px;
}

.trust-img {
    margin-left: 10px;
}

.footer-images {
    margin-top: 15px;
}

footer li a {
    font-size: 16px;
    font-weight: 400;
    color: white;
    text-decoration: none;
    padding-bottom: 10px;
    font-weight: bold;

}

footer li a:hover {
    color: #0f0f0f;
}


.footer-bottom {
    background-color: #171515;
    padding: 40px 0;
    margin-top: 80px;
}

.footer-bottom-heading {
    font-size: 18px;
    color: white;
    /* font-weight: bold; */
    margin: 0;

}

.footer-bottom-heading span {
    color: #bb1900;
    font-weight: bold;
}

.footer-bottom-heading .infinitive {
    color: #e70842;
    font-weight: bold;
}

.footer-bottom-navs ul {
    padding: 0;
    margin: 0;
}

.footer-bottom-navs li {
    font-size: 12px;
    font-weight: bold;
    padding-right: 8px;
    text-transform: uppercase;

}

.footer-bottom-navs li a {
    color: white;

}

.footer-bottom-navs li a:hover {
    color: #e70842;
}




/* logo-desgn-page */
/* .banner-logo-design {
    background-image: url(../logo-finitive-slicing-inner-logo/banner/banner\ bg.png);
    background-repeat: no-repeat;
} */

.banner-img-logo-design {
    padding-top: 155px;
}

/* .banner-content-logo-design {
    padding: 150px 0 100px 0;
} */

/* our custom css */
.our-custom {
    background-color: #211e1e;
    padding: 35px 65px 80px 65px;

}

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent;
}

.dropdown-menu.show {
    margin-top: 20px !important;
}

.our-custom-sub-title {
    color: #d3073e;
    font-size: 45px;
    /* font-style: italic; */

}

.our-custom-title {
    color: white;
    font-weight: bold;
    font-size: 45px;
    margin-bottom: 30px;
}

.our-custom-heading {
    color: white;
    font-size: 36px;
    font-weight: bold;

}

.our-custom-bottom-line {}

.our-custom-heading span {
    color: #d3073e;
    font-size: 56px;
    font-weight: bold;
}

.our-custom-heading .iconic {
    background-color: white;
    height: 3px;
    width: 100px;
}

.logo-design-para {
    color: white;
    font-size: 17px;
    font-weight: 400;

}

.guaranteed_box_bl {
    background: #f2f2f2;
    padding: 30px 30px;
    margin: 50px 0 -10px 0;
}

.guaranteed_box_bl h4 {
    color: #000;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 0;
}

.our-custom-images {
    margin-top: 33px;
}

/* logo design packages */
.logo-design-packages {
    background-image: url(../bg-img//package-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-size: cover;
}

.logo-design-packages-content {
    padding: 60px 0 0px 0;
}

.logo-design-packages-title {
    color: #211e1e;
    font-size: 36px;
    /* font-style: italic; */
}

.logo-design-packages-title span {
    color: white;
    font-size: 62px;
    font-weight: bold;
    font-style: normal;

}

.logo-design-packages-content p {
    color: white;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 150px;

}

/* .logo-design-box ul {
    padding-left: ;
} */



.logo-design-box-2 li,
.logo-design-box li {
    list-style: none;
    color: #252424;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 15px;
}

.logo-design-title {
    background-color: #211e1e;
    color: white;
    text-align: center;
    border-radius: 10px;
    padding: 20px 0;
    font-size: 26px;
    font-weight: bold;
}

.logo-design-box-2,
.logo-design-box {
    background-color: white;
    border-radius: 10px;
    padding-bottom: 54px;
}

.logo-design-box-2 {
    margin-top: -40px;
}

.logo-design-heading {
    color: #d7073e;
    font-size: 56px;
    font-weight: bold;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 40px;
    font-family: 'Poppins', sans-serif;
}

.logo-design-heading span {
    color: #252424;
    font-size: 56px;
    font-weight: bold;
}

.logo-design-order-btn {
    background-color: #d7073e;
    font-size: 17px;
    font-weight: 600;
    padding: 10px 25px;
    border: none;
    margin-top: 50px;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.logo-design-order-btn:hover {
    color: white;
}

.logo-design-box-content {
    padding-bottom: 170px;
}

.testimonials-bg {
    position: absolute;
    top: 0;
    z-index: -1;
}

.testimonials-sec {
    position: relative;
}

/* logo-design-about-us */
/* .logo-design-about-us {
    background-image: url(../logo-finitive-slicing-inner-logo/testimonial/bg.png);
    background-size: cover;
    background-repeat: no-repeat;
} */

.logo-design-about-us-title {
    color: #fa0946;
    font-size: 48px;
    font-weight: bold;
}

.logo-design-about-us-para {
    color: #030303;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.logo-design-about-us-box {
    background-color: white;
    padding: 80px 38px 100px 38px;
}

.logo-design-about-us-content {
    padding: 60px 0 100px 0;
}

.logo-design-about-us-box-heading {
    color: #4c4747;
    font-size: 23px;
    font-weight: bold;
    padding-bottom: 40px;

}

.logo-design-about-us-box p {
    color: #4c4747;
    font-size: 16px;
    font-weight: 400;
}

.logo-design-about-us-box,
.video-img {
    margin-top: 85px;
}

/* pricing-page */
.pricing-tabs .nav-pills .nav-link.active {
    border-bottom: 4px solid black;
    margin-bottom: 100px;
    border-top: none;
    border-right: none;
    border-left: none;
    margin-top: 80px;
    padding-bottom: 0;
}

.pricing-banner .pricing-banner-content {
    padding-bottom: 70px;
}

.pricing-tabs .nav-pills .nav-link {
    margin-top: 80px;



}

.logo-design-box-2 {
    box-shadow: 15px 0 30px 0 rgb(0 0 0 / 18%);
}

.pricing-banner-heading {
    font-size: 100px;
    color: white;
    font-weight: bold;
}

.pricing-sec {
    padding-bottom: 100px;
}

.pricing-sec .logo-design-box {
    box-shadow: 15px 0 30px 0 rgb(0 0 0 / 18%);

}

.logo-design-packages-content p,
.pricing-sec .logo-design-packages-title span {
    color: #000;
    margin-bottom: 0;
}

.pricing-tabs .nav-pills .nav-link.active {
    color: black;
    font-weight: bold;
}

.pricing-sec .nav-pills .nav-link {
    color: #fc0947;
    font-weight: bold;
}


.pricing-banner-content {
    padding: 250px 0 250px 0;
}

.pricing-banner-content p {
    color: white;
}

/* web-design-page-css */
.banner-web-design {
    position: relative;
}

.banner-web-design-content {
    padding: 300px 0 200px 0;
}

.banner-web-design-content p {
    font-size: ;
    color: white;
}

.banner-img-web-design {
    padding-top: 400px;
}

.web-design-details {
    font-size: 17px;
    color: white;
    font-weight: bold;
    margin-bottom: 80px;
}

.web-design-details li {
    margin-bottom: 10px;
    list-style: none;

}

.web-design-details i {
    font-size: 14px;
    margin-right: 7px;

}

.logo-design-about-us-box .slick-dots {
    bottom: -109px;
}

.logo-design-about-us-box .slick-dots li button:before {
    font-size: 15px;
}

.slick-dots li.slick-active button:before {
    color: #fa0946;
}

.new-sec {
    background-color: #171515;
    padding: 50px 0 70px 0;
}

.terms a {
    color: #d7073e;
    font-weight: bold;
}

.sans {
    font-family: sans-serif;
    color: #d7073e;
    text-decoration: none;

}

.new-sec-title {
    color: #d7073e;
    font-weight: 600;
    /* font-style: italic; */
    font-size: 36px;
    margin: 0;
}

.new-sec-main-title {
    font-size: 62px;
    font-weight: bold;
    color: white;
    margin-bottom: 40px;

}


/* animation */





/* about us box css */
.about-box-bg-2,
.about-box-bg {
    background: white !important;
    border-radius: 20px;
    width: 30%;
    height: 140px;
    text-align: center;



}

.about-box-bg-2 {
    margin-top: -25px;
}

.about-us-box-bg-heading {
    font-size: 60px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #fd4110;
}

.about-us-box-bg-heading span {
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
}

.about-us-box-bg-heading-bottom {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}






.new-sec p {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.logo-design-packages p {
    color: white;
    padding-bottom: 130px;
}

/* .content-writing-banner {
    background-image: url(../logo-finitive-slicing/content\ bg.png);
    position: relative
} */

.content-writing-content {
    padding: 300px 0 200px 0;

}

.banner-content-writing-img {
    padding-top: 100px;
}

/* branding-page-css */
/* .banner-branding {
    background-image: url(./Banners/Banners/branding\ bg.png);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
} */

.banner-img-brading {
    position: absolute;
    top: 15%;
    right: 0;
}

.banner-content-branding {

    padding: 350px 0 0px 0;

}

.banner-div img {
    padding-top: 250px;
    padding-bottom: 80px;
}

/* app-development-page-css */
/* .banner-appdevelopment {
    background-image: url(../images/app-bg.png);
} */


.banner-content-app-development {
    padding: 350px 0 0px 0;
}

.banner-img-app-development {
    padding-top: 200px;
}

.banner-content-app-development p {
    color: white;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 30px;
}

/* banner-animation-page-css */
/* .banner-animation {
    background-image: url(../images/animation-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
} */

.banner-img-animation {
    padding-top: 200px;
    padding-bottom: 100px;
}

.dropdown ul li a {
    color: black !important;
    padding-top: 9px;
}

.dropdown a {
    text-decoration: none;
}

.main-navs a {
    padding-top: 0;
}

/* social-media */
/* .banner-social-media {
    background-image: url(../images/social\ media\ bg.png);
} */

.banner-social-media-img {
    padding-top: 200px;
}

.icon-bar a {
    display: block;
    text-align: center;
    padding: 12px;
    transition: all .3s ease;
    color: #000;
    font-size: 20px;
    background-image: -webkit-linear-gradient(0deg, #ea0843 0, #ea0843 100%);
    margin-top: 5px;
    margin-bottom: 5px;
}

.icon-bar {
    position: fixed;
    top: 30%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 99999;
    right: 0;
    display: none;
}

/* modal-css */
.modal-body #floatingTextarea {
    height: 150px;
}

.modal-field-1 {
    position: relative;

}

.modal-profile {
    position: absolute;
    top: 5px;
    left: 5px;
    color: red;

}

/* 
.modal-field-4 ::placeholder,
.modal-field-3 ::placeholder,
.modal-field-2 ::placeholder,
.modal-field-1 ::placeholder {
    padding-left: 20px;
} */

.modal-field-4,
.modal-field-3,
.modal-field-2 {
    position: relative;
}

.modal-arrow-icon,
.modal-phone-icon,
.modal-email-icon {
    position: absolute;
    top: 8px;
    left: 5px;
    color: red;
}


.modal-submit-btn {
    color: white;
    background-color: red;
    border-radius: 60px;
    padding: 10px 60px;
    border: none;
    font-size: 20px;
    font-weight: bold;

}

.modal-content {
    border-radius: 20px;
    padding: 10px 0;
}

.modal-header h5 {
    color: black;
    font-size: 40px;
    font-weight: bolder;
    padding: 0 10px;
}

.modal-header {
    border-bottom: none;
}

.modal-content {
    position: relative;
}

.modal-content .btn-close {
    border: 2px solid red;
    padding: 10px;
    border-radius: 100px;
    position: absolute;
    top: 8px;
    right: 7px;
}

/* form icon */
.phone-div {
    position: relative;
}

.form-icon-f {
    position: absolute;
    top: 0;
}

/* pricing btn live chat css */
.pricing-live-chat {
    font-size: 17px;
    font-weight: 600;
    padding: 10px 25px;
    border: 1px solid #d7073e;
    margin-top: 50px;
    color: #d7073e;
    text-decoration: none;
    margin-left: 10px;
    border-radius: 5px;
}

.pricing-live-chat:hover {
    color: #d7073e;
}

/* axolot-css privery-policy */
.Personal-data {
    color: black;
    font-size: 20px;
    font-weight: bolder;
}

/* thankyou-page */
.thankyou-sec {
    background-color: #fa0946;
    padding-top: 100px;
    padding: 100px;
}

.thankyou-sec {
    color: white;
    font-size: 60px;
    font-weight: bold;

}

.thankyou-sec span {
    font-size: 100px;
    text-align: center;
}

/* animation-page-css */
.animation-pricing-heading {
    font-family: 'Roboto', sans-serif;
    color: #083f6e;
    font-size: 40px;
    text-transform: inherit;
    font-weight: 600;
    margin: 0px;
    line-height: 1;
    text-align: center;
    margin-bottom: 20px;
}

.animation-pricing-heading {
    color: #000;
    font-size: 40px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.animation-pricing-sub-heading {
    font-family: 'Roboto', sans-serif;
    color: #083f6e;
    font-size: 26px;
    text-transform: inherit;
    font-weight: 400;
    margin: 0px;
    line-height: 1;
}

.animation-pricing-sec-title span {
    color: #000000;
    font-size: 62px;
    font-weight: bold;
    font-style: normal;
}

.animation-pricing-box {
    text-align: center;
    box-shadow: 0px 0px 40px 0px rgb(0 0 0 / 20%);
    padding: 70px 10px;
    border-radius: 12px;
    height: 750px;
}

.animation-sec {
    padding-bottom: 100px;
}

.animation-price-heading {
    font-family: 'Roboto', sans-serif;
    color: #e60842;
    font-size: 64px;
    text-transform: inherit;
    font-weight: 900;
}

.animation-pricing-bottom-heading {
    font-family: 'Roboto', sans-serif;
    color: #083f6e;
    font-size: 20px;
    text-transform: inherit;
    font-weight: 400;
    margin: 0px;
    line-height: 1;
}

.animation-pricing-box-2 {
    font-family: 'Roboto', sans-serif;
    background: #ecedf2;
    margin: 40px 0px;
    padding: 30px 30px;
}

.animation-pricing-box img {
    width: 300px;
    margin-top: 45px;
}

.animation-pricing-box-2-heading {
    font-family: 'Roboto', sans-serif;
    color: #083f6e;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
}

.animation-pricing-box-2-para {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #6e768f;
    line-height: 1.5;
    margin-bottom: 30px;
}

.animation_pricing_box img {
    width: 90%;
}

.animation-box-2-heading {
    font-family: 'Roboto', sans-serif;
    color: #083f6e;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
}

.animation-pice-box-2-para {
    font-family: 'Roboto', sans-serif;
    color: #6e768f;
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
}

.animation-pricing-box-2 img {
    width: 78%;
}

.animation-pricing-2-box {
    font-family: 'Roboto', sans-serif;
    padding-bottom: 30px;
}

.animation-pricing-box-btn {
    background: #7739ee;
    background: -moz-linear-gradient(90deg, #eb0843 0%, #eb0843 100%);
    background: -webkit-linear-gradient(90deg, #eb0843 0%, #eb0843 100%);
    background: linear-gradient(90deg, #eb0843 0%, #eb0843 100%);
    margin: 0px;
    color: white;
    text-decoration: none;
    border: 0px;
    -webkit-border: 0px;
    -moz-border: 0px;
    -ms-border: 0px;
    -o-border: 0px;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    padding: 22px 30px;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: none;
    text-align: center;

}

.animation-pricing-box-btn:hover {
    color: white;
}

.nav-tabs.tabs-inner-portfolio .nav-item .nav-link.active {
    color: #fc0947;
    font-weight: bold;
}

.pricing-tabs {
    margin-bottom: 80px;
}

.pricing-bold {
    font-size: 14px;
    color: #252424;
    font-weight: bold;
}


/* ui css end  */
/* font-family: 'Raleway', sans-serif; */
/* font-family: 'Roboto', sans-serif; */

/* classes */
.para-brown {
    color: #878787;
}

.margin-t {
    margin-top: 100px;
}

.margin-b {
    margin-bottom: 100px;
}

.margin-t-22 {
    margin-top: 22px;
}

.margin-t-12 {
    margin-top: 12px;
}

.margin-t-28 {
    margin-top: 28px;
}

.margin-t-25 {
    margin-top: 25px;
}

.color-white {
    color: white;
}

.padding-t {
    padding-top: 95px;
}

.margin-t-80 {
    margin-top: 80px;
}

/* offcanvas menu css */
.offcanvas {
    background-color: #e70842;
}

li {
    list-style: none;
}

.offcanvas-menu li {
    padding-bottom: 20px;
}

.offcanvas-menu li a {
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
}

.offcanvas-body {
    padding-top: 50px;
}

.offcanvas-body .fa-chevron-down {
    padding-left: 10px;
}

.offcanvas-body .collapse ul {
    padding-left: 0;
}

.offcanvas-body .collapse li {
    border-bottom: 1px solid #efefef;
    padding-bottom: 0;
    padding: 10px;

}

.offcanvas-body .collapse li a {
    color: #e70842;

}

.bar {
    text-decoration: none;
}

.offcanvas img {
    width: 213px;
}

/* end */

.banner-side-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    top: 15%;
}

.banner-side-img.web {
    top: 30%;
    width: 60%;
}

.banner-side-img.app {
    width: 40%;
}

.banner-side-img.smm img {
    width: 70%;
}


.banner-side-img.home img {

    width: 100%
}

.num {
    font-size: 20px;
    text-decoration: none;
    font-weight: bold;
    font-family: sans-serif;
    color: #f50844;

}

.num:hover {
    color: white;
}

.bark-widget {
    width: 180px !important;
}

.bark-top-container {
    height: 75px !important;
}

/* sideform */
.floating_wrap .float_form_box .floating_form {
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    top: 40%;
    position: fixed;
    right: -335px;
    width: 400px;
    font-size: 0px;
    background: #ffffff;
    overflow: hidden;
    /* border-radius: 10px 0px 0px 10px; */
    box-shadow: 0px 0px 40px #00000026 !important;
    height: 354px;
    border-radius: 9px;

}

.nft-sec {
    position: relative;
}

.nft-banner-bg {
    position: absolute;
    top: 0;
    z-index: -1;
}

.nft-banner-bg img {
    width: 100%;
}

.nft-sec {
    background-image: url(../images/nft-section-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
    color: white;
}

.d-video-portfolio video {
    width: 100%;
}

.nft-heading {
    font-size: 27px;
}

.nft-heading span {
    font-size: 70px;
    color: #ea0843;
}

.floating_wrap .btns_wrap {
    position: fixed;
    right: 0;
}

.floating_wrap .float_form_box {
    position: fixed;
    right: 0;
}

.floating_wrap .float_form_box .floating_form.open {
    right: 0px;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.floating_wrap .float_form_box .floating_form .floating_strip {
    float: left;
    width: 66px;
    overflow: hidden;
    height: 355px;
    position: relative;
    background: #ea0843;
}

.floating_wrap .float_form_box .floating_form .floating_strip .rotatekaro {
    position: absolute;
    bottom: -70px;
    left: 0;
    transform: rotate(-90deg);
    /* top: 0; */
    /* right: 0; */
}

.floating_wrap .float_form_box .floating_form .floating_inner {
    width: 83.5%;
    background: #fff;
    float: right;
    padding: 20px 30px 7px 30px;
}


.floating_form form .control-group input[type="text"],
.floating_form form .control-group input[type="email"],
.floating_form form .control-group input[type="number"] {
    color: #000;
    font-size: 14px;
    padding: 10px;
    height: 44px;
    width: 100%;
    background: transparent;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-appearance: none;
    border-radius: 4px;
}

.floating_form form .control-group {
    margin-bottom: 10px;
}

.floating_wrap .float_form_box .floating_form .floating_strip .rotatekaro a {
    color: #fff;
    font-size: 24px;
    width: 467px;
    text-align: center;
    height: 66px;
    padding-top: 16px;
    position: absolute;
    transform: rotate(360deg);
    border-radius: 10px 0px 0px 10px;
    text-decoration: none;
    font-weight: bold;
}

.floating_wrap_section:before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.82);
    z-index: -1;
}


.fa-solid.fa-circle-arrow-up {
    font-size: 30px;
    transform: rotate(90deg);
    position: relative;
    left: 30px;
}

.side-form-btn {
    font-size: 14px;
    color: white;
    background: #ea0843;
    text-decoration: none;
    padding: 12px 24px;
    margin-left: 97px;
    font-weight: bold;
}

.side-form-btn:hover {
    color: white;
}

.floating_form form .control-group textarea {
    color: #000;
    padding: 10px;
    height: 80px;
    font-size: 14px;
    width: 100%;
    background: transparent;
    border: 1px solid #cccccc;
    box-shadow: none;
    line-height: 38px;
    border-radius: 4px;
}

.header-btn {
    background-color: #f90946;
    color: white;
    padding: 10px 15px !important;
}

.header-btn:hover {
    color: white !important;
}

/* end */
.thankyou {
    padding-top: 100px;
    padding-bottom: 100px;
}


/* background-color: #eb0843;
    padding: 11px 22px!important;
} */
.header-btn:hover {
    color: white;
}

.month {
    font-size: 30px !important;
}

.nft-flow {
    margin-top: 60px;
}

.banner-web-design.banner-section {

    padding-bottom: 0;
}

.digital-marketing-tabs .nav-tabs.tabs-inner-portfolio .nav-item .nav-link.active {
    color: white !important;
    border: 2px solid white;
    /* padding: 10px 40px; */
    width: 140px;
    height: 45px;
}

.digital-marketing-tabs .nav-tabs.tabs-inner-portfolio .nav-item .nav-link {
    color: black;
    border: 2px solid black;
    /* padding: 10px 40px; */
    width: 140px;
    height: 45px;
}



.nft-services-main-title {
    color: #d3073e;
    font-size: 62px;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
}

.nft-services-sec {
    margin-top: 50px;
    margin-bottom: 95px;
}


.faa-parent.animated-hover:hover>.faa-wrench,
.faa-wrench.animated,
.faa-wrench.animated-hover:hover {
    -webkit-animation: wrench 2.5s ease infinite;
    animation: wrench 2.5s ease infinite;
    transform-origin-x: 90%;
    transform-origin-y: 35%;
    transform-origin-z: initial;
    color: white;

}


.faa-parent.animated-hover:hover>.faa-ring,
.faa-ring.animated,
.faa-ring.animated-hover:hover {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0;
    transform-origin-z: initial;
    color: white;
}

.email-link {
    color: #f50844;
}

.email-link:hover {
    color: white;
}


@-webkit-keyframes wrench {
    0% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    8% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg)
    }

    10% {
        -webkit-transform: rotate(24deg);
        transform: rotate(24deg)
    }

    18% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    20% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    28% {
        -webkit-transform: rotate(24deg);
        transform: rotate(24deg)
    }

    30% {
        -webkit-transform: rotate(24deg);
        transform: rotate(24deg)
    }

    38% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    40% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    48% {
        -webkit-transform: rotate(24deg);
        transform: rotate(24deg)
    }

    50% {
        -webkit-transform: rotate(24deg);
        transform: rotate(24deg)
    }

    58% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    60% {
        -webkit-transform: rotate(-24deg);
        transform: rotate(-24deg)
    }

    68% {
        -webkit-transform: rotate(24deg);
        transform: rotate(24deg)
    }

    75% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }

    2% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }

    4% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    6% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    8% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }

    10% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg)
    }

    12% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }

    14% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }

    16% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }

    18% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg)
    }

    20% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

/* brief form css */
section.wbb-brief.pad-btm {
    padding: 100px 0 20px;
}

.pay-field label {
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: 'Poppins';
}

.wbb-brief .pay-field input,
.wbb-brief .pay-field select,
.wbb-brief .pay-field textarea {
    letter-spacing: 2px;
    border-radius: 8px;
    background: #fff;
}

.pay-field input,
.pay-field select,
.pay-field textarea {
    border: 2px solid #ff5b00;
    background: transparent;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    padding: 0 10px;
}

.w-h h5 {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Poppins';
}


/* brief form css */

section.wbb-brief.pad-btm {
    padding: 100px 0 20px;
}

.pay-field label {
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: 'Poppins';
}

.wbb-brief .pay-field input,
.wbb-brief .pay-field select,
.wbb-brief .pay-field textarea {
    letter-spacing: 2px;
    border-radius: 8px;
    background: #fff;
}

.pay-field input,
.pay-field select,
.pay-field textarea {
    border: 2px solid #ff5b00;
    background: transparent;
    width: 100%;
    height: 50px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    padding: 0 10px;
}

.w-h h5 {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Poppins';
}

.pill-container .selector {
    margin-left: 0 !important;

    margin-right: 10px;
    letter-spacing: 1.1px;
    color: #fff;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;
    background: #ea0843;
    border: 2px solid #ea0843;
    border: none;
    padding: 10px 10px;
    text-transform: uppercase;
    border-radius: 6px;
    font-family: 'Poppins';

    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Poppins';
}

ul.ck-btn label {
    text-transform: uppercase;
    font-size: 15px;
}

ul.ck-btn .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #ea0843;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
}

ul.ck-btn {
    column-count: 4;
    padding: 10px 0px;
}

ul.ck-btn .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 9px;
    height: 9px;
    border: solid #ff5b00;
    border-width: 0 10px 10px 0;
    /* transform: rotate(45deg); */
}

.files-upload {
    text-align: center;
    padding: 15px;
    border: 2px solid #ff5b00;
}

.files-upload label {
    margin-bottom: 20px;
}

.m4-h h6>span {
    color: #ff5b00;
}

.files-upload input#fileToUpload {
    background: transparent;
    font-size: 0;
    position: relative;
}

.files-upload {
    position: relative;
}

.files-upload {
    text-align: center;
    padding: 15px;
    border: 2px solid #ff5b00;
    background: #fff;
    border-radius: 0;
    height: 165px;
}

.m4-h h6 {
    font-size: 35px;
    color: #000;
    font-weight: 400;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.btn-form {
    background-color: #ea0843;
    border: 2px solid #fff;
    border-radius: 50px;
    color: white;
    padding: 12px 30px;
    width: 100%;
    text-decoration: none;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;
    transition-duration: 0.4s;
    letter-spacing: 2px;
    transition: all 0.9s ease;
    font-family: 'Goldman', cursive;
}

.logo-sel.m4-h.pad-btmm {
    padding-bottom: 25px;
}

.pill-container input[type=radio]:checked+label,
.pill-container input[type=checkbox]:checked+label {
    background: #000;
}

input#fileToUpload::after {
    content: "Choose File";
    font-size: 18px;
    color: #000;
    font-weight: 500;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    left: 0;
}

.files-upload input#fileToUpload {
    opacity: 0;
}

.brief span {
    color: red;
    font-size: 18px;
    font-weight: bold;
}

.we-wrp .pay-field.we-half {
    margin-bottom: 12px;
}

.pay-field {
    margin-bottom: 30px;
}

.switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
}



.pill-container {
    margin-bottom: 35px;
}

.pill-container input[type=radio],
.pill-container input[type=checkbox] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.we-wrp .pay-field.we-half {
    margin-bottom: 12px;
}

.doman.switch-field {
    margin-bottom: 0;
    margin-top: 3px;
}

.switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
}

.doman.switch-field label {
    padding: 12px 24px;
}

.switch-field input:checked+label {
    background-color: #ea0843;
    box-shadow: none;
}

.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switch-field label {
    background-color: transparent;
    color: #000;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid #ff5b00;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 30%), 0 1px rgb(255 255 255 / 10%);
    transition: all 0.1s ease-in-out;
    font-family: "hel-lt";
}

.pay-field {
    margin-bottom: 30px;
}

.pay-field label {
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: 'Poppins';
}

.pay-field textarea {
    height: 267px;
    resize: none;
    padding: 20px;
    line-height: normal;
    letter-spacing: 1px;
}

ul.yes-btn>li .switch-field h3 {
    display: inline-block;
    margin-right: -29px;
    width: 85%;
    color: #212529;
    font-family: 'Poppins';
}

ul.yes-btn {
    column-count: 2;
}

.switch-field h3 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 24px;
}

.switch-field {
    margin-bottom: 36px;
    overflow: hidden;
    font-size: 0;
}

.clbx.w-h {
    margin-bottom: 30px;
}

ul.ck-btn .form-group {
    display: block;
    margin-bottom: 15px;
}

ul.ck-btn .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

}

.pill-container {
    margin-bottom: 35px;
}

.pill-container input[type=radio],
.pill-container input[type=checkbox] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.we-wrp .pay-field.we-half {
    margin-bottom: 12px;
}

.doman.switch-field {
    margin-bottom: 0;
    margin-top: 3px;
}

.switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
}

.doman.switch-field label {
    padding: 12px 24px;
}

.switch-field input:checked+label {
    background-color: #ea0843;
    box-shadow: none;
}

.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switch-field label {
    background-color: transparent;
    color: #000;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid #ff5b00;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 30%), 0 1px rgb(255 255 255 / 10%);
    transition: all 0.1s ease-in-out;
    font-family: "hel-lt";
}

.pay-field {
    margin-bottom: 30px;
}

.pay-field label {
    display: block;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: 'Poppins';
}

.pay-field textarea {
    height: 267px;
    resize: none;
    padding: 20px;
    line-height: normal;
    letter-spacing: 1px;
}

ul.yes-btn>li .switch-field h3 {
    display: inline-block;
    margin-right: -29px;
    width: 85%;
    color: #212529;
    font-family: 'Poppins';
}

ul.yes-btn {
    column-count: 2;
}

.switch-field h3 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 24px;
}

.switch-field {
    margin-bottom: 36px;
    overflow: hidden;
    font-size: 0;
}

.clbx.w-h {
    margin-bottom: 30px;
}

ul.ck-btn .form-group {
    display: block;
    margin-bottom: 15px;
}

ul.ck-btn .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

}



.voice-over-list .selector {
    margin-top: 30px;
}

ul.ck-btn .form-group label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Poppins';
}

ul.ck-btn label {
    text-transform: uppercase;
    font-size: 15px;
}

ul.ck-btn .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #ea0843;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 5px;
}

ul.ck-btn {
    column-count: 4;
    padding: 10px 0px;
}

ul.ck-btn .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 9px;
    height: 9px;
    border: solid #ff5b00;
    border-width: 0 10px 10px 0;
    /* transform: rotate(45deg); */
}

.files-upload {
    text-align: center;
    padding: 15px;
    border: 2px solid #ff5b00;
}

.files-upload label {
    margin-bottom: 20px;
}

.m4-h h6>span {
    color: #ff5b00;
}

.files-upload input#fileToUpload {
    background: transparent;
    font-size: 0;
    position: relative;
}

.files-upload {
    position: relative;
}

.files-upload {
    text-align: center;
    padding: 15px;
    border: 2px solid #ff5b00;
    background: #fff;
    border-radius: 0;
    height: 165px;
}

.m4-h h6 {
    font-size: 35px;
    color: #000;
    font-weight: 400;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.btn-form {
    background-color: #ea0843;
    border: 2px solid #fff;
    border-radius: 50px;
    color: white;
    padding: 12px 30px;
    width: 100%;
    text-decoration: none;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;
    transition-duration: 0.4s;
    letter-spacing: 2px;
    transition: all 0.9s ease;
    font-family: 'Goldman', cursive;
}

.logo-sel.m4-h.pad-btmm {
    padding-bottom: 25px;
}

.pill-container input[type=radio]:checked+label,
.pill-container input[type=checkbox]:checked+label {
    background: #000;
}

input#fileToUpload::after {
    content: "Choose File";
    font-size: 18px;
    color: #000;
    font-weight: 500;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    left: 0;
}

.files-upload input#fileToUpload {
    opacity: 0;
}

.brief span {
    color: red;
    font-size: 18px;
    font-weight: bold;
}

.app-brief ul.ck-btn {
    column-count: 1 !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: linear-gradient(#de002e, #bb6b6c);
}

/* end */


/* end  */
.icon-bg {
    background: #ea0843;
    padding: 20px;
    margin-top: 20px;
    border-radius: 0.25rem;
}

.iconsidbar {
    width: 75px;
    position: fixed;
    z-index: 99999;
    top: 30%;
    /*display:none;*/
}

.video-mian-div video {
    width: 100%;
    margin-top: 20px;
}

@media only screen and (min-width: 1040px) {
    .banner-img {
        position: absolute;
        top: 26%;
        right: 0;
        display: flex;
        justify-content: end;
    }


    .banner-img img {
        width: 75%;
    }

    /* .banner-content {
        padding: 385px 0 70px 0;
    } */

    .main-navs li {
        margin: 0;
    }


    header {
        padding-top: 20px;
    }


    .banner-heading {
        font-size: 56px;
    }


    .logo img {
        width: 250px;
    }

    .banner-side-img {
        width: 45%;
    }

    .header-pn {
        display: none
    }

    .header-before {
        display: block;
    }


    .recent-project-tabs.app button {
        width: 150px;
    }

    .floating_wrap .float_form_box .floating_form {
        top: 23%;
        height: 336px;
    }

    .floating_wrap .float_form_box .floating_form .floating_strip .rotatekaro a {
        font-size: 25px;
        width: 507px;
    }

    .fa-solid.fa-circle-arrow-up {
        left: 12px;
    }

    .icon-bar {
        top: 50%;


    }

    /* .icon-bar {
        position: fixed;
        top: 30%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        z-index: 99999;
    } */

    .fa-solid.fa-circle-arrow-up {
        font-size: 27px;
    }

}

/* desktop */
@media only screen and (min-width: 1680px) {

    .header {
        padding-right: 30px;
        padding-left: 30px;
    }

    /* .banner-img {
        position: absolute;
        top: 15%;
        right: 0;
    }

    .banner-img img {
        width: 100%;
    }

    .banner-content {
        padding: 385px 0 340px 0;
    } */
    .main-navs li {
        margin-right: 18px;
    }

    /* .banner-section {
            padding-bottom: 150px;

        } */


    .header-pn {
        display: block
    }

    .header-before {
        display: none;
    }

    .inner-banner-content {
        padding-top: 300px;
    }

    .floating_wrap .float_form_box .floating_form {
        top: 25%;
        height: 354px;
    }

    .floating_wrap .float_form_box .floating_form .floating_strip .rotatekaro a {
        font-size: 27px;
        width: 485px;
    }

    /* .icon-bar {
        position: fixed;
        top: 30%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0;
        z-index: 99999;
    } */
    /* .home-page-bg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
    } */
}




@media (max-width: 767.98px) {
    /* .header {
        display: none;
    } */

    .fa-bars {
        color: white;
        font-size: 30px;
        display: flex;
        justify-content: end;
    }

    .mobile-header {
        top: 11px;
    }

    .nft-heading span {
        font-size: 59px;
    }

    .nft-images {
        margin-top: 50px;
    }

    .nft-services-main-title {
        font-size: 34px;
    }

    .nft-flow-step {
        padding-top: 30px;
    }

    .sm-pt {
        padding-top: 30px;
    }

    /* .nav {
        display: none;
    }

    .logo {
        display: none;
    } */
    .banner-img-web-design,
    .banner-img {
        display: none;
    }


    .banner-btns {
        font-size: 16px;
        /* padding: 10px 8px; */
    }


    /* .banner-web-design-content,
    .banner-content {
        padding: 140px 0 100px 0;
    } */

    .call-to-action-btn {
        margin-right: 0;
        font-size: 16px;
    }

    .about-us-icon-heading {
        font-size: 32px;
    }

    .about-us {
        padding-bottom: 50px;
    }

    .about-us-content {
        padding-top: 45px;
        padding-bottom: 40px;
    }

    .feedback-sec-content-1 {
        padding-top: 50px;
    }

    .feedback-sec-content {
        padding: 50px 0px;
    }


    /* webdesign-mobile */
    .banner-heading {
        font-size: 40px;
    }


    .custom-package {
        background-color: #f90946;
        background-image: none;
    }

    .about-us {
        background-image: none;
        background-color: #000;
    }

    .work-process {
        /* background-image: none; */
    }

    .footer-icons {
        margin-bottom: 20px;
    }


    /* webdesign-mobile */
    .banner-heading {
        font-size: 45px;
    }

    .web-design-details {
        margin-bottom: 40px;
    }

    .logo-design-about-us-title {
        font-size: 40px;
    }

    .inner-banner-content {
        padding-bottom: 50px;
    }

    .floating_wrap {
        display: none;
    }

    .inner-banner-content {
        padding-top: 120px;
    }

    .recent-project-title {
        font-size: 40px;
    }

    .new-sec-main-title {
        font-size: 48px;
    }

    .pricing-banner .pricing-banner-content {
        padding-top: 100px;
    }

    .pricing-banner .pricing-banner-content .banner-btns {
        margin-top: 30px;
    }

    .logo-design-packages-title span {
        font-size: 49px;
    }

    .new-sec {
        padding-bottom: 30px;
    }

    .footer-img {
        width: 50%;
    }

    .footer-bottom {
        padding: 20px 0;
    }

    .inner-banner-content {
        padding-bottom: 40px;
    }

    .sm-top-m {
        margin-top: 80px;
    }

    .our-custom {
        padding: 10px;
    }

    .design-agency-title span {
        font-size: 40px;
    }

    ul.yes-btn.company-guideline {
        column-count: 1;
    }

    ul.yes-btn.company-guideline>li .switch-field h3 {
        margin-right: -66px;
    }

    .social-brief-form ul.ck-btn {
        column-count: 1;
        padding: 10px 0px;
    }

    .about-box-bg-2,
    .about-box-bg {
        width: 45%;
        margin-right: 0;
        margin: 10px;

    }

    .design-agency-title {
        padding-top: 97px;
    }

    .call-to-action-sec a {
        font-size: 14px;
    }


    .about-us-title {
        font-size: 50px;
    }

    .iconsidbar {
        display: none;
    }
}